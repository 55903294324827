import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

import type { Meta } from "@/__main__/router.mjs";
import { appURLs } from "@/app/constants.mjs";
import { BodyDownloadButton } from "@/app/DownloadButtonWrapper.jsx";
import Android from "@/inline-assets/android.svg";
import Mac from "@/inline-assets/mac.svg";
import Windows from "@/inline-assets/windows.svg";
import Container from "@/shared/ContentContainer.jsx";
import { useDownloadLink } from "@/util/download-app.mjs";
import getOSType from "@/util/get-os-type.mjs";

const DownloadLandingVideo = `${appURLs.CDN_VIDEOS}/ui/video/download-landing.webm`;
const DownloadLandingVideoPoster = `${appURLs.CDN_PLAIN}/blitz/ui/images/landing/download/download-landing.webp`;
const MacOSCardImage = `${appURLs.CDN_PLAIN}/blitz/ui/images/landing/download/macos-sq.webp`;
const IosCardImage = `${appURLs.CDN_PLAIN}/blitz/ui/images/landing/download/ios-sq.webp`;
const AndroidCardImage = `${appURLs.CDN_PLAIN}/blitz/ui/images/landing/download/android-sq.webp`;

const VideoContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 100%;
  video {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`;

const DownloadContainer = styled("div")`
  width: 100%;

  .wrapper {
    height: 75vh;
    background: black;
    background: linear-gradient(180deg, black 85%, var(--app-bg) 100%);
    padding-bottom: var(--sp-12);
    margin: 0 calc(var(--page-padding) * -1);

    ${mobile} {
      height: 70vh;
    }
  }
`;

const DownloadTextContainer = styled("div")`
  color: var(--white);
  position: relative;
  bottom: var(--sp-20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: var(--sp-4);

  ${mobile} {
    bottom: var(--sp-24);
    padding-left: var(--sp-0_5);
    padding-right: var(--sp-0_5);
  }

  > h3 {
    line-height: var(--sp-10) !important;
    text-align: center;
  }

  > p {
    margin-top: var(--sp-8);
    text-align: center;
    color: var(--shade2);
  }

  ${mobile} {
    .try-download {
      display: flex;
      flex-direction: column;
    }
  }
`;

const DownloadSubSection = styled("div")`
  margin-top: var(--sp-7) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  > h3 {
    text-align: center;
  }
`;

const SubText = styled("p")`
  color: var(--shade2);
  text-align: center;
  max-width: var(--sp-container);
  ${tablet} {
    max-width: calc(var(--sp-container) / 2);
  }
  ${mobile} {
    max-width: calc(var(--sp-container) / 3);
  }
`;

const CardRow = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--sp-3);
  margin-bottom: var(--sp-5);
  margin-top: var(--sp-5);
  padding-left: var(--sp-4);
  padding-right: var(--sp-4);

  ${tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mobile} {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const CardHeader = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobile} {
    flex-direction: row;
    width: 100%;
    column-gap: var(--sp-2);
    align-items: baseline;
  }
`;

const CardImg = styled("img")`
  width: 100%;
  height: auto;

  ${mobile} {
    display: none !important;
  }
`;

const cssButtonOverride = () => css`
  margin-top: var(--sp-2);
  width: 100%;
  .test {
  }
`;

type DownloadButtonProps = React.PropsWithChildren<{
  href?: string;
  disabled?: boolean;
}>;

const DownloadButton = ({ children, href, disabled }: DownloadButtonProps) => {
  return (
    <a
      href={href}
      download
      target="_blank"
      rel="noopener noreferrer"
      className="w-full"
    >
      <Button
        bgColor="var(--primary)"
        textColor="var(--white)"
        bgColorHover="var(--primary)"
        textColorHover="var(--white)"
        size="large"
        className={cssButtonOverride()}
        disabled={disabled}
      >
        {children}
      </Button>
    </a>
  );
};

const cssIcon = () => css`
  width: var(--sp-6);
  height: var(--sp-6);
  color: var(--shade2);
`;

const cssFor = () => css`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--sp-1);
`;

function Download() {
  const { t } = useTranslation();
  const osType = useMemo(() => getOSType(), []);

  const downloadLabel = "64-bit";

  const downloadUrl = useDownloadLink();

  return (
    <DownloadContainer>
      <div className="wrapper">
        <VideoContainer>
          <video
            playsInline
            autoPlay
            loop
            muted
            poster={DownloadLandingVideoPoster}
          >
            <source src={DownloadLandingVideo} type="video/webm" />
          </video>
          <DownloadTextContainer>
            <h3 className="type-h3">
              {t("home:download.thanks", "Thanks for downloading")}
            </h3>

            <h3 className={`type-h4 ${cssFor()}`}>
              {t("home:download.blitzFor", "Blitz for {{ os }}", {
                os:
                  osType === "darwin"
                    ? t("home:download.macOS", "MacOS")
                    : t("home:download.windows", "Windows"),
              })}
            </h3>

            <p className="type-body2 try-download">
              <BodyDownloadButton
                isPrimary={true}
                size="large"
                textClass="type-form--button"
                target="_blank"
                className="download-blitz-button body"
              />
            </p>
          </DownloadTextContainer>
        </VideoContainer>
      </div>
      <DownloadSubSection>
        <h3 className="type-h3">
          {t("home:download.blitzFromAnyDevice", "Blitz from any device")}
        </h3>
        <SubText className="copy">
          {t(
            "home:download.simplifyProcess",
            "Simplifies the process of mastering difficult games, helping you improve at every step of your journey to the top.",
          )}
        </SubText>
        <CardRow>
          <Card>
            <CardHeader>
              {osType === "darwin" ? (
                <Mac className={cssIcon()} />
              ) : (
                <Windows className={cssIcon()} />
              )}

              <h5 className="type-h5">
                {osType === "darwin"
                  ? t("home:download.macOS", "MacOS")
                  : t("home:download.windows", "Windows")}
              </h5>
              <SubText className="type-caption">
                {t("home:download.desktopApp", "Desktop")} {downloadLabel}
              </SubText>
              <CardImg src={MacOSCardImage} />
            </CardHeader>
            <DownloadButton href={downloadUrl}>
              {t("home:download:downloadText", "Download")}
            </DownloadButton>
          </Card>
          <Card>
            <CardHeader>
              <Mac className={cssIcon()} />
              <h5 className="type-h5">{t("home:download.ios", "iOS")}</h5>
              <SubText className="type-caption">
                {t("home:download.mobileApp", "Mobile App")}
              </SubText>
              <CardImg src={IosCardImage} />
            </CardHeader>
            <DownloadButton disabled={true}>
              {t("common:comingSoonNormal", "Coming Soon")}
            </DownloadButton>
          </Card>
          <Card>
            <CardHeader>
              <Android className={cssIcon()} />
              <h5 className="type-h5">
                {t("home:download.android", "Android")}
              </h5>
              <SubText className="type-caption">
                {t("home:download.mobileApp", "Mobile App")}
              </SubText>
              <CardImg src={AndroidCardImage} />
            </CardHeader>
            <DownloadButton disabled={true}>
              {t("common:comingSoonNormal", "Coming Soon")}
            </DownloadButton>
          </Card>
        </CardRow>
      </DownloadSubSection>
    </DownloadContainer>
  );
}

export function meta(): Meta {
  return {
    title: ["home:meta.download.title", "Blitz.gg - Download"],
    description: [
      "home:meta.download.description",
      "Download Blitz and start improving at League of Legends, TFT, VALORANT, and other games!",
    ],
  };
}

export default Download;
